import {forwardRef} from "react";

export const TextAreaField = forwardRef((props, ref) => (
    <textarea
        ref={ref}
        className="block w-full align-middle text-base text-cultured-700 font-semibold bg-transparent rounded-lg h-60 p-2.5 shadow-none outline-0 focus:outline-none focus:ring-0"
        {...props}
    />
));

export const ChoiceDescription = ({children}) => (
    <div className="text-xs font-normal mt-0.5">
        {children}
    </div>
);
