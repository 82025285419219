import * as React from "react";
import {createPath, useHref, useLinkClickHandler, useSearchParams} from "react-router-dom";
import {getLocationWithUTMs} from "../../utils";
import {LinkProps} from "./types";

const Link = (
    {
        onClick,
        relative,
        reloadDocument,
        replace,
        state,
        target,
        to,
        preventScrollReset,
        ...rest
    }: LinkProps,
    ref: any
) => {
    let [searchParams] = useSearchParams();

    let location = typeof to === 'string' ? to : createPath(to);
    let isAbsolute = /^[a-z+]+:\/\//i.test(location) || location.startsWith('//');

    let navigationLocation = location;
    let isExternal = false;

    if (isAbsolute) {
        let currentUrl = new URL(window.location.href);
        let targetUrl = location.startsWith("//")
            ? new URL(currentUrl.protocol + location)
            : new URL(location);
        if (targetUrl.origin === currentUrl.origin) {
            navigationLocation = targetUrl.pathname + targetUrl.search + targetUrl.hash;
        } else {
            isExternal = true;
        }
    }

    let href = useHref(navigationLocation, {relative});

    let locationWithUTMs = getLocationWithUTMs({navigationLocation, searchParams});

    let internalOnClick = useLinkClickHandler(locationWithUTMs, {
        replace,
        state,
        target,
        preventScrollReset,
        relative,
    });

    let handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (onClick) onClick(event);
        if (!event.defaultPrevented) {
            internalOnClick(event);
        }
    };

    return (
        <a
            {...rest}
            href={isAbsolute ? location : href}
            onClick={isExternal || reloadDocument ? onClick : handleClick}
            ref={ref}
            target={target}
        />
    );
};

export default React.forwardRef(Link);
export type {LinkProps};
