import React from "react";
import styled from "styled-components";

const H = styled.h1`
  font-size: ${({theme, level, scale}) => scale ? scale : level ? theme.titles[level].size : theme.titles[1].size};
  line-height: ${({theme, level}) => level ? theme.titles[level].line : theme.titles[1].line};
  text-align: ${({textAlign}) => textAlign ? textAlign : 'left'};
  font-weight: 700;
  text-rendering: optimizeLegibility;
  color: inherit;
  margin-top: ${({mt}) => `${mt}px` || 0};
  margin-bottom: ${({mb}) => `${mb}px` || 0};

  & + * {
    margin-top: 9px;
  }
`;

const Title = ({children, ...props}) => {
    return (
        React.createElement(
            H,
            {
                as: `h${props.level || 1}`,
                ...props
            },
            children
        )
    )
};

export {Title};
