import React from "react";
import {Toaster as ToasterBase} from "react-hot-toast";

const Toaster = () => (
    <ToasterBase
        toastOptions={{
            className: 'text-sm'
        }}
    />
);

export {Toaster};
