import React, {useRef, useState} from "react";
import {Duration} from "../index";
import styled from "styled-components";
import loadable from "@loadable/component";
import {useBreakpoints} from "../../hooks";

const ReactPlayer = loadable.lib(() => import("react-player"));

const ControlsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const ControlWrapper = styled.div`
    & svg {
        fill: ${({theme}) => theme.colors.gray[8]};
    }
`;

const ProgressWrapper = styled.div`
    flex: 1;
    margin: 1px 0 0 8px;
`;

const VolumeWrapper = styled.div`
    display: flex;
    max-width: 110px;
`;

const Mute = styled.div`
    margin-left: 15px;
    padding-top: 4px;

    & svg {
        fill: ${({state, theme}) => state.muted ? theme.colors.gray[6] : theme.colors.gray[8]};
    }
`;

const Volume = styled.div`
    margin: 1px 0 0 8px;
`;

const Time = styled.div`
    font-size: ${({theme}) => theme.fontSize.s.size};
    margin: 5px 0 0 9px;
`;

const Track = styled.div`
    & input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        margin: 5px 0;
    }

    & input[type=range]:focus {
        outline: none;
    }

    & input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        box-shadow: none;
        background: ${({theme}) => theme.colors.gray[3]};
        border-radius: 25px;
        border: ${({theme}) => `1px solid ${theme.colors.gray[3]}`};
    }

    & input[type=range]::-webkit-slider-thumb {
        box-shadow: none;
        border: ${({theme}) => `1px solid ${theme.colors.gray[8]}`};
        height: 15px;
        width: 15px;
        border-radius: 15px;
        background: ${({theme}) => theme.colors.gray[8]};
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -6px;
    }

,
& input [ type = range ]: focus:: -webkit-slider-runnable-track {
    background: ${({theme}) => theme.backgroundColor};
}
`;

const Player = ({url, minimal, filename}) => {
    const [state, setState] = useState({
        url: url,
        playing: false,
        seeking: false,
        volume: 1,
        muted: false,
        duration: 0,
        loop: false
    });
    const [progress, setProgress] = useState({
        played: 0,
        loaded: 0,
        loadedSeconds: 0,
        playedSeconds: 0,
    });
    const player = useRef(null);
    const breakpoints = useBreakpoints();

    const handlePlayPause = () => {
        setState({
            ...state,
            playing: !state.playing
        })
    };

    const handlePlay = () => {
        setState({
            ...state,
            playing: true
        })
    };

    const handlePause = () => {
        setState({
            ...state,
            playing: false
        })
    };

    const handleEnded = () => {
        setState({
            ...state,
            playing: state.loop
        })
    };

    const handleSeekMouseDown = e => {
        setState({
            ...state,
            seeking: true
        })
    };

    const handleSeekChange = e => {
        setProgress({
            ...progress,
            played: parseFloat(e.target.value)
        })
    };

    const handleSeekMouseUp = e => {
        setState({
            ...state,
            seeking: false
        });

        player.current.seekTo(parseFloat(e.target.value))
    };

    const handleProgress = progress => {
        if (!state.seeking) {
            setProgress(progress)
        }
    };

    const handleDuration = (duration) => {
        setState({
            ...state,
            duration: duration
        })
    };

    const handleVolumeChange = e => {
        setState({
            ...state,
            volume: parseFloat(e.target.value)
        })
    };

    const handleToggleMuted = () => {
        setState({
            ...state,
            muted: !state.muted
        })
    };

    return (
        <>
            {filename && (
                <ReactPlayer>
                    {({default: RPlayer}) => (
                        <RPlayer
                            ref={player}
                            width='100%'
                            height='100%'
                            url={state.url}
                            playing={state.playing}
                            volume={state.volume}
                            muted={state.muted}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            onEnded={handleEnded}
                            onDuration={handleDuration}
                            onProgress={handleProgress}
                        />
                    )}
                </ReactPlayer>
            )}
            <ControlsWrapper>
                <ControlWrapper>
                    {/*{filename && state.playing ? (*/}
                    {/*    <Icon onClick={handlePlayPause}*/}
                    {/*          icon={'pause-circle'}*/}
                    {/*          size={35}*/}
                    {/*    />*/}
                    {/*) : filename && !state.playing ? (*/}
                    {/*    <Icon onClick={handlePlayPause}*/}
                    {/*          icon={'play-circle'}*/}
                    {/*          size={35}*/}
                    {/*    />*/}
                    {/*) : (*/}
                    {/*    <Icon icon={'close-circle'}*/}
                    {/*          size={35}*/}
                    {/*    />*/}
                    {/*)}*/}
                </ControlWrapper>
                {!minimal || state.playing ? (
                    <>
                        <ProgressWrapper>
                            <Track>
                                <input type='range'
                                       min={0}
                                       max={1}
                                       step='any'
                                       value={progress.played}
                                       onMouseDown={handleSeekMouseDown}
                                       onChange={handleSeekChange}
                                       onMouseUp={handleSeekMouseUp}
                                />
                            </Track>
                        </ProgressWrapper>
                        <Time>
                            <Duration seconds={state.duration * progress.played}/> / <Duration
                            seconds={state.duration}/>
                        </Time>
                        <VolumeWrapper>
                            <Mute state={state}>
                                {/*<Icon onClick={handleToggleMuted}*/}
                                {/*      icon={'sound'}*/}
                                {/*      size={24}*/}
                                {/*/>*/}
                            </Mute>
                            {breakpoints.md.viewport && (
                                <Volume>
                                    <Track>
                                        <input type='range'
                                               min={0}
                                               max={1}
                                               step='any'
                                               value={state.volume}
                                               onChange={handleVolumeChange}
                                        />
                                    </Track>
                                </Volume>
                            )}
                        </VolumeWrapper>
                    </>
                ) : null}
            </ControlsWrapper>
        </>
    )
};

export {Player}
