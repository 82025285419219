import React, {useContext, useState} from "react";
import {Button, Col, Divider, Empty, Form, Row, Textarea} from "../index";
import {useAccount} from "../../hooks";
import {setFormFieldError} from "../../helpers";
import {useInView} from "react-intersection-observer";
import dayjs from "dayjs";
import styled, {ThemeContext} from "styled-components";
import {useForm} from "react-hook-form";
import Avatar from "../Avatar";
import toast from "react-hot-toast";
import {EmployeeFragment, getFragmentData} from "@sputnikestate/graph";
import SectionTitle from "../SectionTitle";

const EmptyMessageWrapper = styled.div`
    margin-top: 15px;
    text-align: center;
`;

const NewNoteButtonWrapper = styled.div`
    text-align: center;
    margin-top: 23px;
    font-size: ${({theme}) => theme.fontSize.m.size};
`;

const ActionsButtonsWrapper = styled.div`
    & :not(:first-child) {
        margin-left: 14px;
    }
`;

const MetadataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 7px;
`;

const EmployeeWrapper = styled.div`
    font-size: ${({theme}) => theme.fontSize.xs.size};
    font-weight: 700;
    margin-bottom: 2px;
`;

const DateWrapper = styled.div`
    font-size: ${({theme}) => theme.fontSize.xs.size};
`;

const NoteWrapper = styled.div`
    padding-top: 2px;
    padding-bottom: 38px;
`;

const Employee = styled.div`
    display: flex;
    flex-direction: row;
`;

const Memoji = styled.img`
    height: 90px;
`;

const EmptyMessage = ({formHandler}) => {
    const theme = useContext(ThemeContext);

    return (
        <>
            <Row pb={4} justify={'center'}>
                <Col xs={24} sm={24} lg={16}>
                    <EmptyMessageWrapper>
                        Оставь заметку, чтобы ничего не забыть. Не надейся только на свою голову.
                    </EmptyMessageWrapper>
                    <NewNoteButtonWrapper>
                        <Button kind={'a'}
                                icon={'plus-circle'}
                                iconSize={25}
                                iconColor={theme.colors.blue.primary}
                                onClick={formHandler}
                        >
                            Добавить заметку
                        </Button>
                    </NewNoteButtonWrapper>
                </Col>
            </Row>
        </>
    )
};

const CreateNoteForm = ({submitHandler, formHandler}) => {
    const {user} = useAccount();

    const employee = React.useMemo(
        () => {
            if ('employee' in user) {
                return getFragmentData(EmployeeFragment, user.employee);
            }
            return null;
        },
        [user]
    );

    const {register, handleSubmit, setError, formState: {errors, isSubmitting}} = useForm();

    const onSubmit = async values => {
        await submitHandler(
            {
                note: values.note,
                account: 'id' in user && user.id,
                employee: employee.id
            },
            proceedSubmit
        );
    };

    const proceedSubmit = (error, success) => {
        if (success) {
            toast.success('Заметка успешно добавлена');
            formHandler();
        }
        if (error) {
            setFormFieldError(error.response.data, setError);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row pt={5}>
                <Col xs={24} sm={24}>
                    <Textarea id={'note'}
                              name={'note'}
                              error={errors.note}
                              register={register}
                    />
                </Col>
            </Row>
            <Row pb={58}>
                <Col xs={24} sm={24}>
                    <ActionsButtonsWrapper>
                        <Button
                            width={'180px'}
                            mt={6}
                            disabled={isSubmitting}
                        >Добавить
                        </Button>
                        <Button
                            width={'150px'}
                            type={'tertiary'}
                            mt={6}
                            onClick={formHandler}
                            disabled={isSubmitting}
                        >Отменить</Button>
                    </ActionsButtonsWrapper>
                </Col>
            </Row>
        </Form>
    )
};

const Note = ({note}) => {
    return (
        <div>
            <Employee>
                <Avatar
                    src={note?.employee?.avatar}
                    // mr={10}
                    // height={40}
                    name={note?.employee?.name}
                    surname={note?.employee?.surname}
                />
                <MetadataWrapper>
                    <EmployeeWrapper>
                        {note.employee.short_name}
                    </EmployeeWrapper>
                    <DateWrapper>
                        {dayjs(note.created_at).format('D MMMM YYYY в HH:mm')}
                    </DateWrapper>
                </MetadataWrapper>
            </Employee>
            <NoteWrapper>{note.note}</NoteWrapper>
        </div>
    )
};

const Notes = ({title, url}) => {
    const [showNoteForm, setShowNoteForm] = useState(false);
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    const isLoading = !data && !error;

    const createNote = async (values, callback) => {
        try {
            await mutate(async data => {
                return data;
            })
        } catch (error) {
            if (error.response.status >= 400 && error.response.status < 500) {
                if (callback) callback(error, null);
            } else {
                toast.success('Серьезная ошибка. Уже увидели и исправляем');
            }
        }
    };

    const handleNoteForm = () => {
        setShowNoteForm(!showNoteForm);
    };

    return (
        <>
            <Divider top={53} bottom={54}/>
            <Row pb={5}>
                <Col xs={24} sm={24}>
                    <div ref={ref}>
                        <SectionTitle
                            title={title}
                        />
                        {isLoading && (
                            <div>Загрузка</div>
                        )}
                        {data && data.length > 0 && (
                            <>
                                {data.map((note, i) => <Note key={i} note={note}/>)}
                                {!showNoteForm && <Divider top={5} bottom={34}/>}
                            </>
                        )}
                        {!showNoteForm && !isLoading && (
                            <Row pt={10} pb={48}>
                                <Col xs={24} sm={24}>
                                    <Empty
                                        icon={
                                            <Memoji
                                                src={`${process.env.REACT_APP_STORAGE_HOST}/employees/memoji/1/exploding_head.png`}
                                            />
                                        }
                                        message={
                                            <EmptyMessage
                                                formHandler={handleNoteForm}
                                            />
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                        {showNoteForm && (
                            <CreateNoteForm
                                submitHandler={createNote}
                                formHandler={handleNoteForm}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </>
    )
};

export {Notes};
