import * as React from "react";
import {TIconSVG} from "./types";

const FitnessIcon: TIconSVG = ({color, className}, ref) => (
    <svg ref={ref} className={className} viewBox="0 0 20 14" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M19,4 C18.4,4 18,4.4 18,5 L18,3 C18,2.4 17.6,2 17,2 C16.4,2 16,2.4 16,3 L16,1 C16,0.4 15.6,0 15,0 C14.4,0 14,0.4 14,1 L14,6 L6,6 L6,1 C6,0.4 5.6,0 5,0 C4.4,0 4,0.4 4,1 L4,3 C4,2.4 3.6,2 3,2 C2.4,2 2,2.4 2,3 L2,5 C2,4.4 1.6,4 1,4 C0.4,4 0,4.4 0,5 L0,9 C0,9.6 0.4,10 1,10 C1.6,10 2,9.6 2,9 L2,11 C2,11.6 2.4,12 3,12 C3.6,12 4,11.6 4,11 L4,13 C4,13.6 4.4,14 5,14 C5.6,14 6,13.6 6,13 L6,8 L14,8 L14,13 C14,13.6 14.4,14 15,14 C15.6,14 16,13.6 16,13 L16,11 C16,11.6 16.4,12 17,12 C17.6,12 18,11.6 18,11 L18,9 C18,9.6 18.4,10 19,10 C19.6,10 20,9.6 20,9 L20,5 C20,4.4 19.6,4 19,4 Z"
                fill={color}
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default React.forwardRef(FitnessIcon);
