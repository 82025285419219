import * as React from "react";
import useRouter from "../hooks/useRouter";
import {useServer} from "../hooks/useServer";
import preparePath from "../helpers/urls/path";
import {usePageQuery} from "@sputnikestate/graph";
import lazy from "@fatso83/retry-dynamic-import/react-lazy";

const NotFoundPage = lazy(() => import('../views/NotFound'));
const PropertiesPage = lazy(() => import('../views/Properties/Properties'));
const DevelopmentsPage = lazy(() => import('../views/developments/Developments'));
const SelectionPage = lazy(() => import('../views/landings/Selection'));

const DynamicRoute: React.FC = () => {
    const {pathname} = useRouter();
    const server = useServer();

    const path = React.useMemo(
        () => preparePath(pathname),
        [pathname]
    );

    const {data} = usePageQuery({path});

    const page = data.site.getPage;

    if (page.__typename === 'ContentPage') {
        if (page.type === 'properties') return <PropertiesPage page={page}/>;
        if (page.type === 'developments') return <DevelopmentsPage page={page}/>;
        if (page.type === 'development__selection') return <SelectionPage page={page}/>;
    }

    if (page.__typename === 'MovedPermanentlyPage') {
        if (server) {
            server.statusCode = page.statusCode;
            server.redirect = page.redirect;
            return null;
        }
    }

    return <NotFoundPage/>;
};

export default DynamicRoute;
