import * as React from "react";
import {IButton} from "../types";
import Primary from "../Primary";
import {twMerge} from "tailwind-merge";

const Light = (
    {
        className,
        disabled,
        loading,
        ...rest
    }: IButton, ref?: any) => (
    <Primary
        {...rest}
        ref={ref}
        disabled={disabled}
        loading={loading}
        loadingColor="#1071FF"
        className={twMerge(
            'bg-cultured-100 text-blue-500 hover:bg-cultured-150',
            (loading || disabled) && 'bg-cultured-150',
            className
        )}
    />
);

export default React.forwardRef(Light);
