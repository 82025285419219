import * as React from "react";
import {twMerge} from "tailwind-merge";

interface IAvatar {
    src?: string | null;
    name?: string | null;
    surname?: string | null;
    className?: string;
}

const Avatar: React.FC<IAvatar> = ({src, name, surname, className}) => {
    const initial = React.useMemo(
        () => {
            if (surname && name) return `${surname.charAt(0).toUpperCase()}${name.charAt(0).toUpperCase()}`;
            if (!surname && name) return `${name.charAt(0).toUpperCase()}`;
            if (!surname && !name) return '—';
            return '—';
        },
        []
    );

    return (
        <div
            className={twMerge(
                'flex justify-center items-center rounded-full overflow-hidden',
                className && className
            )}
        >
            {src ? <img src={src}/> : initial}
        </div>
    )
};

export default Avatar;
