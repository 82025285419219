import * as React from "react";
import {useUser} from "@sputnikestate/graph";
import dayjs from "dayjs";
import {useCookies} from "react-cookie";

interface IAccount extends ReturnType<typeof useUser> {
}

const accountContext = React.createContext<IAccount>({} as IAccount);

const AccountProvider = ({children}: { children: React.ReactNode }) => {
    const account = useAccountProvider();

    return (
        <accountContext.Provider value={account}>
            {children}
        </accountContext.Provider>
    );
};

const useAccount = () => {
    return React.useContext(accountContext);
};

const useAccountProvider = () => {
    const {user} = useUser();
    const [{sputnik_user_token}, setCookie, removeCookie] = useCookies(['sputnik_user_token']);

    React.useEffect(
        () => {
            if (user.loggedIn) {
                setTokenCookie();
            } else {
                removeTokenCookie();
            }
        },
        [user, sputnik_user_token]
    );

    const setTokenCookie = () => {
        if ('token' in user && user.token && sputnik_user_token !== user.token) {
            setCookie('sputnik_user_token', user.token, {
                path: '/',
                expires: dayjs().add(7, 'day').toDate(),
                secure: process.env.NODE_ENV === 'production',
                sameSite: 'lax',
            });
        }
    };

    const removeTokenCookie = () => {
        removeCookie('sputnik_user_token');
    };

    return {user};
};

export {AccountProvider, useAccount};
