import React from "react";
import styled from "styled-components";

const P = styled.p`
  font-size: ${({size, theme}) => (size) ? theme.fontSize[size].size : theme.fontSize.m.size};
  text-align: ${({textAlign}) => textAlign ? textAlign : 'left'};
  color: ${({color, theme}) => color ? color : theme.textColor};
  margin-top: ${({mt}) => mt ? mt : '0px'};
`;

const PostTitle = ({children, ...props}) => {
    return (
        <P {...props}>{children}</P>
    )
};

export {PostTitle};
