import * as React from "react";
import {twMerge} from "tailwind-merge";

const InputField = ({className, ...props}: React.InputHTMLAttributes<HTMLInputElement>, ref: any) => (
    <input
        ref={ref}
        className={twMerge(
            'block w-full h-full align-middle text-base text-cultured-700 font-semibold bg-transparent rounded-lg px-3.5 shadow-none outline-0 focus:outline-none focus:ring-0',
            className,
        )}
        {...props}
    />
);

export default React.forwardRef(InputField);
