import * as React from "react";
import {TIconSVG} from "./types";

const WalkIcon: TIconSVG = ({color, className}, ref) => (
    <svg ref={ref} className={className} viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.25089 3.24414C8.87239 3.24414 9.37622 2.74177 9.37622 2.12207C9.37622 1.50237 8.87239 1 8.25089 1C7.62939 1 7.12556 1.50237 7.12556 2.12207C7.12556 2.74177 7.62939 3.24414 8.25089 3.24414ZM6.20296 7.59277L5.96851 9.30702L4.2805 12.0498C4.09294 12.3459 4.18672 12.7199 4.48368 12.9069C4.59309 12.9693 4.7025 13.0004 4.81191 13.0004C5.0151 13.0004 5.23391 12.8914 5.34332 12.7043L7.10949 9.83688C7.15638 9.75896 7.18764 9.68104 7.20327 9.58753L7.35957 8.43431L6.20296 7.59277ZM9.95458 5.11495L11.5801 5.70715C11.8271 5.78926 11.9856 6.01264 11.9956 6.25766C11.9989 6.33817 11.9861 6.42101 11.9552 6.50195C11.8458 6.82922 11.4863 7.00065 11.1581 6.87597L9.37628 6.22143C9.2825 6.19027 9.18872 6.12793 9.11057 6.03442L8.65731 5.47339L8.25094 7.15649L10.0134 8.5C10.1697 8.60909 10.2635 8.7961 10.2635 8.9987V12.3727C10.2635 12.7156 9.98218 12.9961 9.63832 12.9961C9.29447 12.9961 9.01313 12.7156 9.01313 12.3727V9.31038L7.40693 8.09155L6.25033 7.26558L6.73485 5.09937L6.06277 5.39547L5.17578 6.9539C5.05075 7.15649 4.84756 7.26558 4.62874 7.26558C4.51934 7.26558 4.40993 7.25 4.31615 7.18766C4.11526 7.07169 4.0002 6.85588 4 6.63672C3.9999 6.5319 4.02608 6.42632 4.0817 6.33052L5.07809 4.60067C5.20473 4.40426 5.43269 4.30888 5.63655 4.22359C5.65915 4.21414 5.68145 4.20481 5.70328 4.19548L7.20374 3.55652C7.31315 3.49418 7.43819 3.44743 7.56322 3.43185C7.70389 3.41626 8.25094 3.46302 8.39161 3.4786C8.69398 3.52885 8.88464 3.78163 9.06182 4.01653C9.10445 4.07304 9.14629 4.12852 9.18872 4.17989C9.31376 4.33574 9.44271 4.49158 9.57165 4.64742C9.7006 4.80327 9.82954 4.95911 9.95458 5.11495Z"
            fill={color}
        />
    </svg>
);

export default React.forwardRef(WalkIcon);
