import * as React from "react";

interface InputLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    children: string;
}

const InputLabel = ({children}: InputLabelProps, ref: any) => (
    <label ref={ref} className="text-xs text-gray-500 mb-2 leading-3">
        {children}
    </label>
);

export default React.forwardRef(InputLabel);
