import * as React from "react";
import {FieldError, useController} from "react-hook-form";
import {
    Choice,
    Choices as ChoicesBase,
    TChoicesNotTypedValue,
    TChoicesStyle,
    TChoicesType,
    TChoicesValueType
} from "../Fields/Choices";

interface IChoices {
    control: any;
    label?: string;
    type: TChoicesType;
    style: TChoicesStyle
    name: string;
    valueType: TChoicesValueType;
    defaultValue: TChoicesNotTypedValue;
    error?: FieldError;
    containerClassName?: string;
    children: React.ReactNode;
}

export const Choices: React.FC<IChoices> = (
    {
        control,
        label,
        type,
        style,
        name,
        valueType,
        defaultValue,
        error,
        containerClassName,
        children
    }
) => {
    const dValue = React.useMemo(
        () => {
            if (defaultValue === '') return null;
            return defaultValue;
        },
        [defaultValue]
    );

    const {field: {value, onChange, ...rest}} = useController({
        control, name, defaultValue: dValue
    });

    return (
        <ChoicesBase
            label={label}
            value={value}
            type={type}
            style={style}
            valueType={valueType}
            error={error}
            containerClassName={containerClassName}
            onChange={onChange}
            {...rest}
        >
            {children}
        </ChoicesBase>
    );
};

export {Choice};
