import * as React from "react";
import SortFilter from "../../Filters/SortFilter";
import ContentLoader from "react-content-loader";
import {useTranslation} from "react-i18next";
import {PlusIcon} from "@heroicons/react/24/solid";
import {FiltersType, SortOptionsType, UpdateFilterType} from "../../../types";
import {AdjustmentsHorizontalIcon} from "@heroicons/react/20/solid";
import {twMerge} from "tailwind-merge";
import PrimaryButton from "../../primitives/Buttons/Primary";
import {
    GetContactsQuery,
    GetDealsQuery,
    GetDevelopmentsQuery,
    GetLeadsQuery,
    GetPropertiesQuery,
    GetTasksQuery,
    GetWhatsappAccountsQuery,
    QueryReference,
    useReadQuery
} from "@sputnikestate/graph";

interface ITitlePane {
    title?: string | null;
    description?: string | null;
    showCreate?: boolean;
    onCreatePress?: () => void;
}

interface IFilterPane {
    updateFilter?: UpdateFilterType;
    handleFilters?: () => void;
    sortOptions?: SortOptionsType;
    filtersCount?: number;
    filters?: FiltersType;
}

interface ICount {
    queryRef: QueryReference<GetPropertiesQuery> |
        QueryReference<GetDevelopmentsQuery> |
        QueryReference<GetWhatsappAccountsQuery> |
        QueryReference<GetContactsQuery> |
        QueryReference<GetDealsQuery> |
        QueryReference<GetLeadsQuery> |
        QueryReference<GetTasksQuery>;
}

interface ICountPane extends ICount {
    count?: number;
    filtersCount?: number;
    resetFilters?: () => void;
    countPaneClassName?: string;
}

interface IHeader extends ITitlePane, IFilterPane, ICountPane {
    className?: string;
}

const TitlePane: React.FC<ITitlePane> = ({title, description, showCreate, onCreatePress}) => (
    <div className="grid grid-cols-[4fr] lg:grid-cols-[3fr_1fr] pt-0.5 pb-0.5">
        {title && (
            <div className="col-start-1 col-span-1 row-start-1 row-span-1">
                <h1 className="text-3xl sm:text-4xl text-slate-900 font-black tracking-tight">
                    {title}
                </h1>
            </div>
        )}
        {description && (
            <div className="col-start-1 col-span-2 lg:col-span-1 row-start-2 row-span-1">
                <div className={
                    twMerge(
                        'font-medium text-slate-600 text-base md:text-xl mt-3',
                        showCreate && 'mt-2.5 md:mt-3'
                    )
                }>
                    {description}
                </div>
            </div>
        )}
        <div
            className="col-start-2 lg:col-start-3 col-span-1 row-start-1 row-span-1 lg:row-span-2 flex justify-end items-center"
        >
            {showCreate && (
                <button
                    className="flex justify-center items-center focus:outline-none bg-cultured-100 hover:bg-cultured-150 rounded-full h-10 w-10 md:h-11 md:w-11 -mt-0.5"
                    onClick={onCreatePress}
                >
                    <PlusIcon className="h-6 w-6 md:h-7 md:w-7 text-blue-500"/>
                </button>
            )}
        </div>
    </div>
);

const FilterPane: React.FC<IFilterPane> = ({updateFilter, handleFilters, sortOptions, filtersCount, filters}) => {
    const {t} = useTranslation(['general']);

    if (!handleFilters && !sortOptions) return null;

    return (
        <div className="w-full mt-5">
            <div className="flex flex-row md:justify-between gap-2">
                {handleFilters && (
                    <PrimaryButton
                        title={t('lists.filters', {ns: 'general'})}
                        onClick={handleFilters}
                        className={'lg:w-56'}
                        prefix={(
                            <AdjustmentsHorizontalIcon
                                className="h-5 w-5 text-white"
                            />
                        )}
                        suffix={(
                            <span className="flex justify-center items-center bg-blue-300 h-6 w-6 rounded-full text-xs">
                            <span>{filtersCount}</span>
                        </span>
                        )}
                    />
                )}
                {updateFilter && sortOptions && (
                    <SortFilter
                        name={'ordering'}
                        options={sortOptions}
                        filters={filters}
                        updateFilter={updateFilter}
                    />
                )}
            </div>
        </div>
    );
};

const Count: React.FC<ICount> = ({queryRef}) => {
    const {t} = useTranslation(['general']);

    const {data} = useReadQuery<
        GetPropertiesQuery |
        GetDevelopmentsQuery |
        GetWhatsappAccountsQuery |
        GetContactsQuery |
        GetDealsQuery |
        GetLeadsQuery |
        GetTasksQuery
    >(queryRef as any);

    const count = React.useMemo(
        () => {
            if ('properties' in data) return data.properties.getProperties.count;
            if ('developments' in data) return data.developments.getDevelopments.count;
            if ('contacts' in data) return data.contacts.getContacts.count;
            if ('deals' in data) return data.deals.getDeals.count;
            if ('leads' in data) return data.leads.getLeads.count;
            if ('tasks' in data) return data.tasks.getTasks.count;
            if ('whatsapp' in data) return data.whatsapp.getAccounts.count;
            return 0;
        },
        [data]
    );

    return (
        <div className="text-xs md:text-sm text-slate-600 font-semibold">
            {t('lists.foundWithCount', {ns: 'general', count: count})}
        </div>
    );
};

const CountLoader: React.FC = () => (
    <ContentLoader
        width="120"
        height="20"
        viewBox="0 0 120 20"
        backgroundColor="#F3F5F7"
        foregroundColor="#DEE3E9"
    >
        <rect x="0" y="0" rx="4" ry="4" width="120" height="20"/>
    </ContentLoader>
);

const CountPane: React.FC<ICountPane> = (
    {
        queryRef,
        filtersCount,
        resetFilters,
        countPaneClassName
    }
) => {
    const {t} = useTranslation('common');

    return (
        <div
            className={twMerge(
                'grid grid-cols-2 mt-3 md:mt-6 mb-3 md:mb-2.5',
                countPaneClassName
            )}
        >
            <div className="justify-self-start">
                <React.Suspense fallback={<CountLoader/>}>
                    <Count queryRef={queryRef}/>
                </React.Suspense>
            </div>
            <div className="justify-center justify-self-end">
                {!!(resetFilters && filtersCount && filtersCount > 0) && (
                    <button
                        className="flex focus:outline-none text-xs md:text-sm text-blue-500 hover:text-blue-600 font-semibold"
                        onClick={resetFilters}
                    >
                        {t('lists.reset')}
                    </button>
                )}
            </div>
        </div>
    );
}

const Header: React.FC<IHeader> = (
    {
        title,
        description,
        sortOptions,
        handleFilters,
        showCreate,
        onCreatePress,
        queryRef,
        filtersCount,
        updateFilter,
        resetFilters,
        filters,
        className,
        countPaneClassName
    }
) => (
    <div className={twMerge('mt-5', className)}>
        <TitlePane
            title={title}
            description={description}
            showCreate={showCreate}
            onCreatePress={onCreatePress}
        />
        <FilterPane
            updateFilter={updateFilter}
            handleFilters={handleFilters}
            sortOptions={sortOptions}
            filtersCount={filtersCount}
            filters={filters}
        />
        <CountPane
            queryRef={queryRef}
            filtersCount={filtersCount}
            resetFilters={resetFilters}
            countPaneClassName={countPaneClassName}
        />
    </div>
);

export {Header};
